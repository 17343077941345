import { errorMessageStore } from '../stores/ErrorMessageStore';

import { UnauthorizedError, FileNotFoundError, FileDownloadError } from './ErrorHandler';

declare let process: {
    env: Record<string, {} | string | boolean | number | undefined>;
};

export function getEnvVar<T>(key: string, fallback: T): T {
    const value: T = process.env[key] as T;
    if (value != null) {
        return value;
    }

    return fallback;
}
export function isNullOrEmpty(pString: string | null): boolean {
    return (pString == null || pString === '');
}
export function validateUrl(pUrl: string): boolean {
    const tRegExp: RegExp = new RegExp(/^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/i);
    if (!tRegExp.test(pUrl)) {
        return false;
    }
    return true;
}
export function getFileExtension(pFile: string): string {
    if (pFile == null) {
        return ('');
    }
    return pFile.substr(pFile.lastIndexOf('.') + 1);
}
export function getFileName(pFile: string): string {
    if (pFile == null) {
        return ('');
    }
    return pFile.substr(pFile.lastIndexOf('/') + 1);
}

export function inArray(pString: string, pArray: Array<string>): boolean | null {
    return (pArray == null || pString == null || pArray.indexOf(pString) > -1);
}
export function getUrlParamValue(pUrl: string, pParam: string): string | null {
    if (pUrl == null || pParam == null) {
        return (null);
    }

    // The url might contain a query param "edit" and param with an encoded "edit" parameter too.
    // if (pUrl.includes('sharepoint.com') && pParam === 'edit') {
    if ((new URL(pUrl)).host.endsWith('sharepoint.com') && pParam === 'edit') {
        return getSharePointParamEditValue(pUrl);
    }

    if (pParam === 'view' && pUrl.indexOf('view=http')) {
        // case 2 view parameters:
        // https://impress.pcon-planner.com/de/
        // ?view=1&p-pl-x.edition=PRO&p-pl-x.platform=Win64&p-pl-x.major=8&p-pl-x.minor=5&p-pl-x.subminor=0&p-pl-x.build=101
        // &view=https://1drv.ms/u/s!AkhnWhCu5M4ljTEE_DoF1ZYU6peT
        pUrl = '&' + pUrl.substring(pUrl.indexOf('view=http'));
    }

    const tRegExp: RegExp = new RegExp('[?|&]' + pParam + '=' + '([^&;]+?)(&|#|;|$)');
    const tResult: RegExpExecArray | null = tRegExp.exec(pUrl);
    if (tResult != null && tResult.length > 1) {
        const tValue: string = tResult[1].replace(/\+/g, '%20');
        return (decodeURIComponent(tValue));
    }
    return (null);
}
// export const ResponseDataType = ['blob','json'];
export function getSharePointParamEditValue(pUrl: string): string | null {
    const parts: Array<string> = pUrl.split('edit=');
    const link: string | null = parts.length > 0 ? parts[1] : null;
    return link;
}
// let apiurl: string = 'https://impress.pcon-solutions.com/next/php/getsptext.php?url=' + pUrl.split('resolveUrl.php?url=')[1];
// let apiurl: string = './php/getsptext.php?url=' + pUrl.split('resolveUrl.php?url=')[1];

// let apiurl: string = 'https://impress.pcon-solutions.com/next/php/getspt.php?url=' + pUrl.split('resolveUrl.php?url=')[1];
// let apiurl: string = './php/getspt.php?url=' + pUrl.split('resolveUrl.php?url=')[1];
export async function fetchData(decodedInput: string, init: RequestInit | {}, as: 'Blob' | 'ArrayBuffer' | 'Json'):
    Promise<Blob | ArrayBuffer | undefined> {
    // This function is used to load the file Data (not to get the extension)  RequestInfo | URL
    console.warn('fetchData: Must implement other input checks');

    if (decodedInput.startsWith('http')) {
        if (isSharepoint(decodedInput, 'fetchData')) { // same origin
            console.warn('isshartpoint');
            decodedInput = getPhpApiBaseUrl() + 'php/getspt.php?url=' + encodeURIComponent(decodedInput);
        }
    } else {
        console.warn('INFO: It must be a call to an access point? ', decodedInput);
    }
    // Due to issue with iOS, the url must NOT be cross origin!
    // let response: Response | undefined = undefined;
    try {
        const response: Response = await (
            fetch(decodedInput, init).catch((error) => {
                // Note: there is some strange bug where Safari 17.6 caches failed requests
                // Therefor the download should be triggered a second time without using the cache
                console.warn('suppressed error', error);
                return fetch(decodedInput, {
                    ...init,
                    cache: 'reload',
                });
            })
        );
        console.warn('2nd Response: ', response);
        console.warn('as? ', as);
        if (response && response.ok) { // Http responce 200-299
            // console.warn('--------------------------> error.message: ', response.status);
            // console.warn('--------------------------> error.message: ', response.statusText);
            switch (as) {
                case 'Blob':
                    const arrayBuffer: ArrayBuffer = await response.arrayBuffer(); // console.log('*a', arrayBuffer);
                    const blob: Blob = new Blob([arrayBuffer]); // console.log('*b', blob);
                    return blob; // await response.blob(); // issues in iOS
                case 'ArrayBuffer':
                    return await response.arrayBuffer();
                case 'Json':
                    return await response.json();
                default:
                    return undefined;
            }
        } else if (response) { // code > 299
            console.warn('Http reponse, status: ' + response.status);
            switch (typeof response.status) {
                case 'object': {
                    console.warn('type: object');
                    errorMessageStore.open(new FileDownloadError());
                    break;
                }
                case 'string': {
                    console.warn('type: string');
                    // switch (error.substr(0, 3)) {
                    switch (response.statusText.substring(0, 3)) { // substr
                        case '401': {
                            // throw new UnauthorizedError();
                            errorMessageStore.open(new UnauthorizedError());
                            break;
                        }
                        case '404': {
                            // throw new FileNotFoundError();
                            errorMessageStore.open(new FileNotFoundError());
                            break;
                        }
                        default: {
                            // throw new FileDownloadError();
                            errorMessageStore.open(new FileDownloadError());
                            break;
                        }
                    }
                    break;
                }
                default: {
                    errorMessageStore.open(new FileDownloadError());
                    // throw new FileDownloadError();
                }
            }
        }
    } catch (error) {
        console.warn('Network error... is not an Http reponse, error: ', error);
        // console.warn('fetch error: ', error);
        // console.warn('fetch response: ', response);
        // console.warn('fetch error message: ', response?.status );
        errorMessageStore.open(new FileDownloadError());
        // throw Error(response.statusText);
        // errorMessageStore.open(error as FileDownloadError);
        // errorMessageStore.open(new FileDownloadError());
        return undefined;
    }

    return undefined;
}
export function isOWDSlink(url: string) {
    return url.toLowerCase().includes('owds.');
}
export function isSharepoint(decodedUrl: string, caller?: string) {
    console.warn('isSharepoint.decodedUrl, caller: ' + decodedUrl + ' / ' + caller);
    const urlInterface: URL = new URL(decodedUrl);
    return urlInterface.hostname.includes('sharepoint.com');
}
export function getPhpApiBaseUrl() {
    // since our test environments cannot currently run php, we use the live servers
    return 'https://impress.pcon-solutions.com/';
}
export function getGUID(): string {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c: string) {
        const r: number = Math.random() * 16 | 0;
        const v: number = c === 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}
export function getCloudDownloadUrl(pUrl: string): string {
    if (pUrl == null) {
        return ('');
    }

    let tUrl: string = pUrl;

    if (pUrl.indexOf('dropbox.com') > -1) {
        tUrl = getDropboxUrl(pUrl);
    } else if ((pUrl.indexOf('drive.google') > -1) && (pUrl.indexOf('uc?') === -1)) {
        tUrl = getGoogleDriveUrl(pUrl);
    } else if (pUrl.indexOf('1drv.ms')) {
        tUrl = getOneDriveUrl(pUrl);
    } else if (pUrl.indexOf('sharepoint.com')) {
        tUrl = getSharePointUrl(pUrl);
    }

    return tUrl;
}
export function getShareUrl(): string {
    const input = document.querySelector('input[name="url"]')!;
    if (input == null) {
        return '';
    }

    const url: string = (input as HTMLInputElement).value;
    return location.origin + location.pathname.substring(0, location.pathname.lastIndexOf('/')) + '/iframe.html?url=' + url;
}
function getDropboxUrl(pUrl: string): string {
    // https://www.dropbox.com/s/bs4p8gp7t6zqdad/EasternGraphics.png?dl=0
    // NEW 2023.06
    // https://www.dropbox.com/scl/fi/9tnmr92esrhwe2few3fyi/Shared_Model_2023-06-12_10-26-27.pec?dl=0&rlkey=o9ql5hbuue6bcux32t19wkjc4
    // https://uc5cb97191563bddc5abd27452a7.dl.dropboxusercontent.com/cd/0/get/
    // B9_ZXlVxTNvQZhHvmEm374tiJWXxu_awMZUXw1cqDu2YzGqUjMdDSz3ztpPU3PNrnOmg2lmPekbKqNXwJ9SB5RiPHEIWzkQ2PCZgeamhPi7I0dPlT-
    // zwveWbLTgFE5Ap3FfWoqrPvbL7wndvH-GwKb5BhiOdMLIJhU7bu1s5TQY3sCBkbJfucMaYAHEXdwAJzyU/file?_download_id=
    // 6911945787892503909048510856163588820288152832231855691215005133&_notify_domain=www.dropbox.com&dl=1
    if (pUrl.includes('https://www.dropbox.com/scl/fi/')) {
        return pUrl.replace('www', 'dl');
    }

    const tRegExp: RegExp = new RegExp(/.*\/s\/([^\?]+)\??.*/);
    let tUrl: string = pUrl;
    try {
        const tRegExpResult: RegExpExecArray | null = tRegExp.exec(pUrl);
        if (tRegExpResult) {
            const tUid: string = tRegExpResult[1];
            tUrl = 'https://dl.dropboxusercontent.com/s/' + tUid;
        }
    } catch {
        return pUrl;
    }

    return tUrl;
}
// https://drive.google.com/file/d/1MmMfGChs-_-F3umGhQJKFiESwD9Tittq/view?usp=sharing
// https://drive.google.com/uc?export=download&id=1MmMfGChs-_-F3umGhQJKFiESwD9Tittq
// https://impress.pcon-solutions.com/next/php/resolveUrl.php?
// url=https%3A%2F%2Fdrive.google.com%2Fuc%3Fid%3D1MmMfGChs-_-F3umGhQJKFiESwD9Tittq%26export%3Ddownload
// "https://doc-00-6k-docs.googleusercontent.com/docs/securesc/ha0ro937gcuc7l7deffksulhg5h7mbp1/ug18e5jgdd9pndmnuluo6lmqsej9ihhb/1603462425000/03540858861347457638/*/1MmMfGChs-_-F3umGhQJKFiESwD9Tittq?e=download"
function getGoogleDriveUrl(pUrl: string): string {
    let tUrl: string = pUrl;
    try {
        let tRegExp: RegExp = new RegExp(/.*\/open\?id=(.*)/);
        let tRegExpResult: RegExpExecArray | null = tRegExp.exec(pUrl);
        if (tRegExpResult) {
            const tUid: string = tRegExpResult[1];
            tUrl = 'https://drive.google.com/uc?id=' + tUid + '&export=download';

        } else {
            tRegExp = new RegExp(/.*\/d\/(.*)/);
            tRegExpResult = tRegExp.exec(pUrl);
            if (tRegExpResult) {
                let tUid: string = tRegExpResult[1];
                tUid = tUid.split('/view?')[0]; // generic
                // tUid = tUid.replace('/view?usp=sharing', '');
                // tUid = tUid.replace('/view?usp=drivesdk', '');
                tUrl = 'https://drive.google.com/uc?id=' + tUid + '&export=download';

            }
        }
    } catch {
        return pUrl;
    }
    return tUrl;
}
function getOneDriveUrl(pUrl: string): string {
    return pUrl;
}
function getSharePointUrl(pUrl: string): string {
    console.warn('getSharePointUrl', pUrl);
    return pUrl;
}
export function getEmbedUrl(url: string, encodeBtoa: boolean): string {
    // const embed: string = encode_btoa ? btoa(url) : encodeURIComponent(url as string);
    // return location.origin + location.pathname.substring(0, location.pathname.lastIndexOf('/')) + '/?embed=' + embed;
    // https://impress.pcon-solutions.com/next1/?url=https%3A%2F%2Fwww.dropbox.com%2Fs%2Fykom39f6miavb1v%2Ftest.pec%3Fdl%3D0
    return url.replace('url=', 'embed=');
}